import {Fragment} from 'react';
// import {GoLightBulb as Bulb} from 'react-icons/go';
// import {
//   RiPieChart2Line as PieChart,
//   RiUserLocationLine as HR,
//   RiMacLine as Machine,
// } from 'react-icons/ri';
// import {TiShoppingCart as Cart} from 'react-icons/ti';
// import {BsChatRightText as Chat} from 'react-icons/bs';
// import {TfiHeadphoneAlt as BPO} from 'react-icons/tfi';
// import {GoLaw as Legal} from 'react-icons/go';

import Image from 'next/image';
import {Section, Grid, Box} from '@jobseeker/lotus/lib/foundations';
import Link from '@jobseeker/lotus/lib/elements/Link';
import useInterceptor from '@jobseeker/common-core/lib/hooks/useInterceptor';

import {ROUTE_CATEGORY, ROUTE_TAG} from '@constants';

import {
  BankingFinanceJobs,
  SalesMarketingJobs,
  ConsultingJobs,
  HRIRJobs,
  ITSystemJobs,
  SCMOperationJobs,
  LegalJobs,
  BPOJobs,
} from '@jobseeker/lotus/lib/assets/icons';
// import Icons from './Icons';

import type {
  FindOpportunityType,
  JobOpportunityNavType,
  LinkType,
} from '../types';
import {Typography} from '@mui/material';
import {handleTracking} from '@jobseeker/common-core/lib/utils';

const FindOpportunity = (props: FindOpportunityType) => {
  const {eventTag, heading, theme, isAboveMobile} = props || {};

  const interceptor: /* eslint-disable @typescript-eslint/no-explicit-any */ any =
    useInterceptor();

  const {
    header: {
      meta: {tabsList},
    },
  } = interceptor;

  // const iconsArray = [PieChart, Cart, Chat, HR, Machine, Bulb, Legal, BPO];

  const iconsArray = [
    [BankingFinanceJobs, 'Banking and Finance Jobs'],
    [SalesMarketingJobs, 'Sales and Marketing Jobs'],
    [ConsultingJobs, 'Consulting Jobs'],
    [HRIRJobs, 'HR IR Jobs'],
    [ITSystemJobs, 'IT System Jobs'],
    [SCMOperationJobs, 'SCM Operations Jobs'],
    [LegalJobs, 'Legal Jobs'],
    [BPOJobs, 'BPO Jobs'],
  ];

  const getCatTagPageLink = (routePath: string, tag = '') => {
    return `${routePath}/${tag
      .replace('&', '')
      .replace(/\s+/g, '-')
      .toLowerCase()}-jobs`;
  };

  // const Icon = ({index}: {index: number}) => (
  //   <Icons index={index} iconsArray={iconsArray} />
  // );

  return tabsList && tabsList.length ? (
    <Section>
      <Typography
        component={'h1'}
        sx={{
          textAlign: 'center',
          fontSize: {
            xxs: theme.typography.pxToRem(20),
            md: theme.typography.pxToRem(26),
          },
          fontWeight: {xxs: 600, md: 400},
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        }}
      >
        {heading}
      </Typography>
      <Grid
        container
        sx={{
          px: {xxs: 3, sm: 3, md: 5, lg: 8},
          marginTop: {xxs: theme.spacing(3.125), md: theme.spacing(5.625)},
        }}
      >
        {tabsList.map((tab: JobOpportunityNavType, indexVal: number) => (
          <Fragment key={`home-findopportunity-${indexVal}`}>
            <Grid
              item
              // key={`${indexVal}${Date.now()}`}
              xxs={12}
              sm={3}
              sx={{
                width: {xxs: '100%', md: '25%'},
                // height: {xxs: theme.spacing(50), md: theme.spacing(43.75)},
                // height: isAboveMobile
                //   ? theme.spacing(43.75)
                //   : theme.spacing(50),
                height: 'auto',
                display: 'flex',
                borderTop: `${theme.spacing(0.125)} solid ${
                  theme.palette.gray.light
                }`,
                borderBottom: {
                  xxs: '0',
                  sm: `${theme.spacing(0.125)} solid ${
                    theme.palette.gray.light
                  }`,
                },
                borderLeft: `${theme.spacing(0.125)} solid ${
                  theme.palette.gray.light
                }`,
                borderRight: isAboveMobile
                  ? '0'
                  : `${theme.spacing(0.125)} solid ${theme.palette.gray.light}`,
                '&:nth-of-type(4n)': {
                  borderRight: `${theme.spacing(0.125)} solid ${
                    theme.palette.gray.light
                  }`,
                },
                [theme.breakpoints.down('sm')]: {
                  '&:nth-of-type(8n)': {
                    borderBottom: `${theme.spacing(0.125)} solid ${
                      theme.palette.gray.light
                    }`,
                  },
                },
                [theme.breakpoints.up('md')]: {
                  '&:nth-of-type(1n)': {
                    borderBottom: `${theme.spacing(0.125)} solid ${
                      theme.palette.gray.light
                    }`,
                  },
                  '&:nth-of-type(5n)': {
                    borderTop: 0,
                  },
                  '&:nth-of-type(6n)': {
                    borderTop: 0,
                  },
                  '&:nth-of-type(7n)': {
                    borderTop: 0,
                  },
                  '&:nth-of-type(8n)': {
                    borderTop: 0,
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  textAlign: {xxs: 'center', sm: 'left'},
                  margin: {
                    xxs: `${theme.spacing(3.75)} ${theme.spacing(3.75)}`,
                    sm: `${theme.spacing(3.75)} ${theme.spacing(3.125)}`,
                    md: `${theme.spacing(4.375)} ${theme.spacing(5)}`,
                    xl: `${theme.spacing(5.625)} ${theme.spacing(5)}`,
                  },
                }}
              >
                <Box
                  sx={{
                    fontSize: theme.typography.pxToRem(32),
                    color: 'primary.main',
                    justifyContent: 'center',
                  }}
                >
                  <Image
                    src={iconsArray[indexVal][0]}
                    alt={iconsArray[indexVal][1]}
                    width={50}
                    height={50}
                  />
                </Box>
                <Link
                  href={getCatTagPageLink(ROUTE_CATEGORY, tab['tag'])}
                  target="_blank"
                  key={`home-findopportunity-${indexVal}`}
                  sx={{
                    fontWeight: 'bold',
                    fontSize: {
                      xxs: theme.typography.pxToRem(18),
                      md: theme.typography.pxToRem(16),
                      xl: theme.typography.pxToRem(14),
                    },
                    padding: 0,
                    opacity: 1,
                    cursor: 'pointer',
                    marginBottom: theme.spacing(1.25),
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                  }}
                  onClick={() => {
                    //navToJobCategoryAndTagPage(ROUTE_CATEGORY);
                    handleTracking(eventTag, {
                      event_category: 'engagement',
                      Label: tab['heading'],
                      Value: `${indexVal}`,
                    });
                  }}
                >
                  {tab['heading']}
                </Link>
                {tab.menu.slice(0, 6).map((link: LinkType, index: number) => {
                  return (
                    <Link
                      key={`home-findopportunity-menu${index}`}
                      target="_blank"
                      href={getCatTagPageLink(ROUTE_TAG, link['tag'])}
                      sx={{
                        p: 0,
                        marginBottom: theme.spacing(0.625),
                        marginTop: theme.spacing(0.625),
                        fontSize: {
                          xxs: theme.typography.pxToRem(16),
                          sm: theme.typography.pxToRem(10),
                          lg: theme.typography.pxToRem(13),
                        },
                        opacity: 1,
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                      }}
                      onClick={() => {
                        //navToJobCategoryAndTagPage(ROUTE_TAG);
                        handleTracking(eventTag, {
                          event_category: 'companies',
                          Label: link['label'] || '',
                          Value: `${index}`,
                        });
                      }}
                    >
                      {link['label']}
                    </Link>
                  );
                })}
              </Box>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Section>
  ) : (
    <></>
  );
};
export default FindOpportunity;
